var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-heading', {
    attrs: {
      "as": "h3",
      "font-family": "Montserrat",
      "font-weight": "bold",
      "font-size": "24px",
      "line-height": "36px",
      "mb": "24px"
    }
  }, [_vm._v(" Follow Up Notes ")]), !_vm.isEmptyReminder ? _c('c-box', [_c('c-text', {
    attrs: {
      "font-weight": "500",
      "font-size": "18px",
      "line-height": "150%",
      "color": "superDarkGray.900"
    }
  }, [_vm._v(" Pengisian Follow Up Notes ")]), _c('hr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "mt": "10px",
      "mb": "18px"
    }
  }), _c('c-alert', {
    attrs: {
      "border-radius": "6px",
      "py": "30px",
      "px": "40px",
      "mb": "32px",
      "bg": "rgba(244, 204, 70, 0.2)",
      "justify-content": "space-between"
    }
  }, [_c('c-box', [_c('c-text', {
    attrs: {
      "font-weight": "500",
      "font-size": "18px",
      "line-height": "27px",
      "color": "black",
      "mb": "4px"
    }
  }, [_vm._v(" Anda belum mengisi Follow Up Notes untuk Hari ke-" + _vm._s(_vm.reminder.day) + " ")]), _c('c-text', {
    attrs: {
      "font-weight": "400",
      "font-size": "16px",
      "line-height": "24px",
      "color": "gray"
    }
  }, [_vm._v(" Klik tombol di samping untuk mengisi Follow Up Notes untuk Hari ke-" + _vm._s(_vm.reminder.day) + " ")])], 1), _c('c-icon-button', {
    attrs: {
      "w": "50px",
      "h": "50px",
      "variant-color": "orange",
      "aria-label": "Drink coffee",
      "icon": "arrow-forward",
      "border-radius": "24px",
      "as": "router-link",
      "to": {
        name: 'nutri.follow-up-notes.form',
        query: {
          id: _vm.reminder.followUpNoteId
        }
      }
    }
  })], 1)], 1) : _vm._e(), _c('c-text', {
    attrs: {
      "font-weight": "500",
      "font-size": "18px",
      "line-height": "150%",
      "color": "superDarkGray.900"
    }
  }, [_vm._v(" History Follow Up Notes ")]), _c('hr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "mt": "10px",
      "mb": "18px"
    }
  }), _c('c-grid', {
    attrs: {
      "template-columns": ['repeat(1, 1fr)', 'repeat(2, 1fr)'],
      "gap": "6"
    }
  }, _vm._l(_vm.histories, function (item, index) {
    return _c('c-flex', {
      key: index,
      attrs: {
        "border": "1px solid #C4C4C4",
        "box-sizing": "border-box",
        "border-radius": "12px",
        "py": "24px",
        "px": "27px",
        "pr": "10px",
        "bg": item.isDraft && !item.submitAt ? 'superLightGray.900' : 'white',
        "justify-content": "space-between",
        "gap": "1"
      }
    }, [_c('c-box', [_c('c-text', {
      attrs: {
        "font-weight": "500",
        "font-size": "18px",
        "line-height": "27px",
        "color": "black",
        "mb": "4px"
      }
    }, [_vm._v(" Follow Up Notes " + _vm._s(_vm.getFormatDate(item.createdAt)) + " ")]), _c('c-text', {
      attrs: {
        "font-weight": "400",
        "font-size": "18px",
        "line-height": "27px",
        "color": "lightGray.900"
      }
    }, [_vm._v(" Diisi pada " + _vm._s(item.submitAt ? _vm.getFormatDate(item.submitAt) : '-') + " ")])], 1), item.editable ? _c('c-flex', {
      attrs: {
        "align": "center"
      }
    }, [_c('c-button', {
      attrs: {
        "p": "0",
        "bg": "unset"
      },
      on: {
        "click": function click($event) {
          return _vm.onFollowupNote(item);
        }
      }
    }, [_c('c-image', {
      attrs: {
        "w": "24px",
        "h": "24px",
        "src": require('@/assets/icon-chevron-right.svg')
      }
    })], 1)], 1) : _vm._e()], 1);
  }), 1), _vm.isEmpty ? _c('c-flex', {
    attrs: {
      "py": "80px",
      "px": "20px",
      "justify-content": "center"
    }
  }, [_c('c-box', [_c('c-image', {
    attrs: {
      "h": ['189px', '300px'],
      "src": require('@/assets/empty-nutritionists.svg'),
      "alt": "empty"
    }
  }), _c('c-text', {
    attrs: {
      "mt": "41px",
      "color": "gray.900",
      "font-size": ['14px', '24px'],
      "line-height": ['21px', '36px'],
      "text-align": "center"
    }
  }, [_vm._v(" Histori tidak ditemukan ")])], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }