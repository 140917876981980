<template>
  <c-box>
    <c-heading
      as="h3"
      font-family="Montserrat"
      font-weight="bold"
      font-size="24px"
      line-height="36px"
      mb="24px"
    >
      Follow Up Notes
    </c-heading>
    <c-box v-if="!isEmptyReminder">
      <c-text
        font-weight="500"
        font-size="18px"
        line-height="150%"
        color="superDarkGray.900"
      >
        Pengisian Follow Up Notes
      </c-text>
      <hr
        v-chakra
        mt="10px"
        mb="18px"
      >
      <c-alert
        border-radius="6px"
        py="30px"
        px="40px"
        mb="32px"
        bg="rgba(244, 204, 70, 0.2)"
        justify-content="space-between"
      >
        <c-box>
          <c-text
            font-weight="500"
            font-size="18px"
            line-height="27px"
            color="black"
            mb="4px"
          >
            Anda belum mengisi Follow Up Notes untuk Hari ke-{{ reminder.day }}
          </c-text>
          <c-text
            font-weight="400"
            font-size="16px"
            line-height="24px"
            color="gray"
          >
            Klik tombol di samping untuk mengisi Follow Up Notes untuk Hari ke-{{ reminder.day }}
          </c-text>
        </c-box>
        <c-icon-button
          w="50px"
          h="50px"
          variant-color="orange"
          aria-label="Drink coffee"
          icon="arrow-forward"
          border-radius="24px"
          as="router-link"
          :to="{
            name: 'nutri.follow-up-notes.form',
            query: { id: reminder.followUpNoteId },
          }"
        />
      </c-alert>
    </c-box>

    <c-text
      font-weight="500"
      font-size="18px"
      line-height="150%"
      color="superDarkGray.900"
    >
      History Follow Up Notes
    </c-text>
    <hr
      v-chakra
      mt="10px"
      mb="18px"
    >

    <c-grid
      :template-columns="['repeat(1, 1fr)', 'repeat(2, 1fr)']"
      gap="6"
    >
      <c-flex
        v-for="(item, index) in histories"
        :key="index"
        border="1px solid #C4C4C4"
        box-sizing="border-box"
        border-radius="12px"
        py="24px"
        px="27px"
        pr="10px"
        :bg="item.isDraft && !item.submitAt ? 'superLightGray.900' : 'white'"
        justify-content="space-between"
        gap="1"
      >
        <c-box>
          <c-text
            font-weight="500"
            font-size="18px"
            line-height="27px"
            color="black"
            mb="4px"
          >
            Follow Up Notes {{ getFormatDate(item.createdAt) }}
          </c-text>
          <c-text
            font-weight="400"
            font-size="18px"
            line-height="27px"
            color="lightGray.900"
          >
            Diisi pada {{ item.submitAt ? getFormatDate(item.submitAt) : '-' }}
          </c-text>
        </c-box>
        <c-flex
          v-if="item.editable"
          align="center"
        >
          <c-button
            p="0"
            bg="unset"
            @click="onFollowupNote(item)"
          >
            <c-image
              w="24px"
              h="24px"
              :src="require('@/assets/icon-chevron-right.svg')"
            />
          </c-button>
        </c-flex>
      </c-flex>
    </c-grid>

    <c-flex
      v-if="isEmpty"
      py="80px"
      px="20px"
      justify-content="center"
    >
      <c-box>
        <c-image
          :h="['189px', '300px']"
          :src="require('@/assets/empty-nutritionists.svg')"
          alt="empty"
        />
        <c-text
          mt="41px"
          color="gray.900"
          :font-size="['14px', '24px']"
          :line-height="['21px', '36px']"
          text-align="center"
        >
          Histori tidak ditemukan
        </c-text>
      </c-box>
    </c-flex>

    <!-- <router-view /> -->
  </c-box>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import moment from 'moment'
import _ from 'lodash'
export default {
  name: 'FollowUpNotesPage',
  computed: {
    ...mapState({
      histories: (s) => s.nutriFollowUpNotes.historyFollowupNotes,
      reminder: (s) => s.nutriFollowUpNotes.reminderFollowupNotes,
    }),
    ...mapGetters({
      profileGizi: 'mealPlan/profileGizi',
    }),
    isEmptyReminder() {
      return _.isEmpty(this.reminder)
    },
    isEmpty() {
      return this.histories.length === 0
    },
  },
  async mounted() {
    this.loadFollowupNotes(this.clientId)
    this.loadReminder(this.clientId)
    await this.$store.dispatch('mealPlan/getProfileGizi', this.clientId)
  },
  methods: {
    ...mapActions({
      loadFollowupNotes: 'nutriFollowUpNotes/getFollowUpNotesByClientId',
      loadReminder: 'nutriFollowUpNotes/getFollowUpNotesReminder',
    }),
    getFormatDate(date) {
      moment.locale('id')
      return moment(date).format('D MMMM yyyy')
    },
    onFollowupNote(item) {
      if (item.isDraft) {
        this.$router.push({
          name: 'nutri.follow-up-notes.form',
          query: { id: item.id },
        })
      } else {
        this.$router.push({
          name: 'nutri.follow-up-notes.history',
          query: { id: item.id },
        })
      }
    },
  },
}
</script>

<style scoped>
.m-tabs {
  border-color: transparent;
  border-bottom-width: 5px;
}
.m-tabs.router-link-active {
  border-bottom-width: 5px;
  border-color: #008c81;
  color: #008c81;
}
</style>
